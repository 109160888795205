@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  @include verticalGap(32px);
  z-index: 0;
}

.icon {
  text-align: center;
  margin-bottom: 16px;
}

.titleArea {
  @include verticalGap(12px);
}

.title {
  @include h1;
  text-align: center;
}

.subtitle {
  @include subtitle;
  text-align: center;
}
