@import 'src/styles/variables';
@import 'src/styles/mixins';

.totalLoanLabel {
  color: $black100;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 32px;
}

.datePicker {
  margin-top: 8px;
}

.label {
  color: $black80;
}

.hint {
  text-align: center;
  margin-top: 24px;
  color: $black80;
  font-size: 14px;
}

.button {
  margin-top: 32px;
}

.loaderContainer {
  @include offerContainer($background: true);
  height: 100%;
  width: 100%;
  color: $black100;
  margin-left: -16px;
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: content-box;

  @include sm {
    margin: 24px auto 48px;
    padding: 40px 160px 32px;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .fadeContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
