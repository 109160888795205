@import 'src/styles/variables';

.questionWrapper {
  margin: 0 auto;
}

.datePicker {
  margin-top: 8px;
}

.label {
  margin-bottom: 16px;
  color: $black80;
}

.hint {
  text-align: center;
  margin-top: 24px;
  color: $black80;
  font-size: 14px;
}

.button {
  margin-top: 32px;
}
