@import 'src/styles/variables';
@import 'src/styles/mixins';

$stepperLineWidth: 65px;

.container {
  @include stepsContainer;
  text-align: center;
  padding: 16px 24px;
  @include verticalGap(16px);
}

.container.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  @include h1;
}

.subtitle {
  @include body-text('md');
}

.bold {
  font-weight: $boldFontWeight;
}

.thanksLabel {
  @include body-text('md');
  color: $black80;
}

.stepperContainer {
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepperItem {
  position: relative;

  &:not(:last-child) {
    margin-right: $stepperLineWidth;
  }
}

.iconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: $black10;
  border-radius: 50%;

  > svg {
    path {
      fill: $black40;
    }
    width: 24px;
    height: 24px;
  }
}

.stepperItem:not(:last-child) .iconContainer::after {
  content: '';
  position: absolute;
  left: 100%;
  height: 2px;
  width: $stepperLineWidth;
  background-color: $black10;
}

.rewardCompleted.stepperItem .iconContainer::after {
  background-color: $green;
}

.rewardCompleted.stepperItem:has(+ .rewardActive) .iconContainer::after {
  background: linear-gradient(90deg, $green 0%, $irisLight 70%);
}

.rewardActive.stepperItem:has(+ .reward) .iconContainer::after {
  background: linear-gradient(180deg, $irisLight 0%, $black10 70%);
}

.rewardActive .iconContainer {
  background-color: $irisLight;

  svg {
    path {
      fill: $primaryColor;
    }
  }
}

.rewardCompleted .iconContainer {
  background-color: $green;

  svg {
    path {
      fill: none;
      stroke: $white;
    }
  }
}
